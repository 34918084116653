
<template>
  <div>
    <div class="bg-white border-red px-1 px-sm-3 pb-3 mt-3">
      <b-row class="mt-3">
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="data">
              <router-link :to="'/automation/detail/' + data.item.id">
                <u> {{ data.item.name }}</u>
              </router-link>
            </template>
            <template v-slot:cell(valid_from)="data">
              <div class="d-flex justify-content-center">
                {{
                  $moment(data.item.valid_from).format("DD/MM/YYYY hh:mm:ss A")
                }}
              </div>
            </template>
            <template v-slot:cell(valid_to)="data">
              <div class="d-flex justify-content-center">
                {{
                  $moment(data.item.valid_to).format("DD/MM/YYYY hh:mm:ss A")
                }}
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <div
                :class="[
                  'd-flex justify-content-center',
                  data.item.active == 1 ? 'text-success' : 'text-danger',
                ]"
              >
                {{ data.item.active == 1 ? "Active" : "Inactive" }}
              </div>
            </template>
            <template v-slot:cell(is_draft)="data">
              <div class="d-flex justify-content-center">
                <span v-if="data.item.is_draft === 1">Draft</span>
                <span v-if="data.item.is_draft === 0" class="save-green"
                  >Saved</span
                >
              </div>
            </template>
            <template v-slot:cell(action)="data">
              <div class="d-flex justify-content-center">
                <router-link :to="'/automation/detail/' + data.item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
                <b-button
                  variant="link"
                  class="text-danger px-1 py-0"
                  @click="deleteData(data.item.id)"
                >
                  <font-awesome-icon
                    icon="trash-alt"
                    title="Delete"
                    class="main-color"
                  />
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="form-inline justify-content-center justify-content-sm-start mb-2 mb-sm-0">
          <p class="mb-0 p-gray">
            Showing
            <span v-if="items.length === 0" id="from-row">0</span>
            <span v-else id="from-row">{{ (filter.page - 1) * filter.take + 1 }}</span> to
            <span id="to-row">
              {{
                filter.take * filter.page > rows ? rows : filter.take * filter.page
              }}</span
            >
            of <span id="total-row">{{ rows }}</span> entires
          </p>
        </b-col>
        <b-col cols="12" md="6" class="form-inline justify-content-center justify-content-sm-end">
          <div>
            <b-pagination
              v-model="filter.page"
              :total-rows="rows"
              :per-page="filter.take"
              @change="pagination"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              class="m-md-0"
            ></b-pagination>
          </div>
          <div class="ml-1">
            <b-form-select
              v-model="filter.take"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      toShow: this.showingTo,
    };
  },
  methods: {
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.toShow = this.filter.take;
      this.$emit("perpage", this.filter);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.toShow = this.showing + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
    deleteData(id) {
      this.$emit("delete", id);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.save-green {
  color: green;
}
</style>
